
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #f7631b;
}
.home_banner{
    background-image: url(../../assets/img/newHome/home_banner.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 126px;
}
.header_font{
    font-family: Suez One;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 115.5%; /* 73.92px */
    text-transform: capitalize;
    color: #fff;
    
}
.service_content{
    text-align: justify;
    font-size: 20px;
}
.header_sub_font{
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; 
    text-align: justify;
}
.get_started{
    border-radius: 30px;
    padding: 10px 20px;
    background: #F7631B;
    cursor: pointer;
    color: #fff !important;
}
.bg-orange{
    background-color: #F7631B;
    padding: 50px;
}
.number{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}
.request_text{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 29.5px;
    margin-top: 25px;
}
.second_banner{
    background-image: url(../../assets/img/newHome/second_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.grades{
    color: #121212;
    text-align: center;
    font-family: Suez One;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 115.5%; /* 55.44px */
    text-transform: capitalize;
}
.experience{
    color: rgba(14, 14, 14, 0.81);
    text-align: center;
    font-family: Inter;
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
}
.grade_block{
    text-decoration: none !important;
    margin: 35px;
    padding: 30px;
    text-decoration: none !important;
    border-radius: 10px;
    background: linear-gradient(180deg, #FFF 0%, #FFF 100%);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15), 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
    width: 340px;
    height: 320px;
}
.block_sub_text{
    color: rgba(14, 14, 14, 0.81);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.5px;
    margin-top: 30px;
}
.block_heading{
    color: #121212;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 115.5%;
    margin-top: 20px;
}
.block_img{
    background-color: rgb(247, 99, 27);
    padding: 8px;
    border-radius: 7px;
}
.expert_bg{
    background-image: url(../../assets/img/newHome/expert_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.expert_text{
    color: #FFF;
    text-align: center;
    font-family: Suez One;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 115.5%;
    margin-top: 20px;
}
.work_text{
    color: rgb(247, 99, 27);
    font-size: 25px;
    font-weight: 500;
    line-height: 1.2;
}
.work_sub_text{
    color: #000;
    font-size: 20px;
    text-align: justify;
    font-weight: 400;
}
.testi_card{
    margin: 27px;
    background-color: #fff;
    box-shadow: 0px 0px 8px 3px #888888;
    padding: 26px;
    height: 230px;
}
.test_heading{
    color: #F7631B;
    font-size: 30px;
    margin-bottom: 20px;
}
.test_sub_text{
    color: #000;
    text-align: justify;
    font-weight: 500;
    font-size: 18px;
}
.black_bg{
    border-radius: 30px;
    background: linear-gradient(180deg, #272727 0%, #2D2D2D 80%);
    margin-top: 20px;
}
.form_heading{
    font-size: 30px;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 15px;
    line-height: 35px;
}
.field{
    border-radius: 30px;
    height: 50px;
}
.bg-black{
    background-color: #000;
    padding: 50px;
}
