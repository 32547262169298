/* =================================== */
/*  Menu Styles
/* =================================== */


/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.nav-menu .active{
}
.nav-menu .active2{
  color:#f7631b;
}

.header-top{
  background:#000;
  font-size: 12px;
  padding:8px;
  a{
    color:#fff;
  }
  ul li{
    display:inline-block;
    margin-left: 15px;
  }


  .header-top-left{
    text-align:left;
    i{
      @include transition();
    }
    a{
      margin-right:10px;
    }
    a:hover{
      @include transition();
      i{
        color:#f7631b;
      }
    }
  }

  .header-top-right{
    text-align: right;
    a{
        margin-right:10px;
      .lnr{
        display:none;
        font-weight:700;
      }
    }
  
    @media(max-width:480px){
      a{
        .lnr{
          display:inline-block;
        }
        .text{
          display:none;
        }
      }
    }

  }

}

.top-head-btn{
  background:#f7631b;
  padding:9px 26px;
}

.main-menu{
  padding-bottom:10px ;
  padding-top:10px ;
}

#header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 997;
  background-color: #000;
}

@media(max-width:673px){
  #logo{
      margin-left:20px;
  }
}

#header #logo h1 {
  font-size: 34px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 3px;
}

#header #logo h1 a, #header #logo h1 a:hover {
  color: #000;
  padding-left: 10px;
  border-left: 4px solid #f7631b;
}

#header #logo img {
  padding: 0;
  margin: 0;
}

@media (max-width: 768px) {
  #header #logo h1 {
    font-size: 28px;
  }
  #header #logo img {
    max-height: 40px;
  }
}


/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Nav Menu Essentials */
.nav-menu, .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  right: 0;
  z-index: 99;
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu > li {
  float: left;
}

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
  display: block;
}

.nav-menu ul ul {
  top: 0;
  right: 100%;
}

.nav-menu ul li {
  min-width: 180px;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
  padding-right: 30px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Container */

@media (max-width: 768px) {
  #nav-menu-container {
    display: none;
  }
}

/* Nav Meu Styling */
.nav-menu a {
  padding: 0 8px 0px 8px;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  font-weight:500;  
  font-size: 12px;
  text-transform: uppercase;
  outline: none;
}

.nav-menu li:hover > a{
  color: #f7631b;
}

.nav-menu > li {
  margin-left: 10px;
}

.nav-menu ul {
  margin: 24px 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  background: #fff;
}



.nav-menu ul li {
  transition: 0.3s;
}

.nav-menu ul li a {
  padding: 5px 10px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 12px;
  text-transform: none;
}

.nav-menu ul li:hover > a {
  color: #f7631b;
}

.nav-menu ul ul {
  margin-right: 10px;
  margin-top:0;
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
  position: fixed;
  right: 15px;
  z-index: 999;
  top: 17px;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
  /* background:rgba(4, 9, 30, 0.8); */
}

#mobile-nav-toggle i {
  color: #fff;
  font-weight: 900;  
}

@media (max-width: 960px) {
  #mobile-nav-toggle {
    display: inline;
  }
  #nav-menu-container{
    display:none;
  }
}

/* Mobile Nav Styling */
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.8);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
  font-weight: 400;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #f7631b;
}

#mobile-nav ul .menu-has-children li a {
  text-transform: none;
}

#mobile-nav ul .menu-item-active {
  color: #f7631b;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}

/* Mobile Nav body classes */
body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

.sf-arrows .sf-with-ul {
  padding-right: 30px;
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu ul {
  margin: 24px 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127,137,161,0.25);
  background: #fff;
}

.nav-menu ul li a {
  padding: 5px 10px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 12px;
  text-transform: none;
}

.bckg{
  background: rgba(6, 6, 6, 0.71);
}

.tongle_text{
  color:#f7631b;
  font-weight: 600;
}

.btn-tongle {
  color: #fff;
  background-color: #f7631b;
  border-color: #f7631b;
}