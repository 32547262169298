.about_second_sec{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 70px;
}
.about_second_sec img{
    max-width: 100%; /* Ensures the image doesn't exceed the container width */
    height: auto; 
}
.about_text{
    font-size: 20px;
    color: #000;
    text-align: justify;
    line-height: 1.3;
}
