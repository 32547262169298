/* Header Section */
.blog-header {
    background: #f8f9fa;
    padding: 60px 0;
    text-align: center;
}

.blog-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.breadcrumb {
    font-size: 1rem;
    color: #666;
}

.breadcrumb a {
    color: #007bff;
    text-decoration: none;
}

.breadcrumb a:hover {
    text-decoration: underline;
}

/* Blog Container */
.blog-container {
    padding: 40px 0;
}

/* Blog Card */
.blog-card {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.blog-content {
    padding: 20px;
}

.blog-content h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.blog-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Truncate to 3 lines */
    -webkit-box-orient: vertical;
}

.btn-primary {
    background-color: #f7631b;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #000;
    color: #fff;
}

/* Load More Button */
.btn-load-more {
    background-color: #f7631b;
    border: none;
    padding: 10px 30px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn-load-more:hover {
    background-color: #0056b3;
}

.btn-load-more:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}