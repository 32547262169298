/* Header Section */
.blog-details-header {
    background: #f8f9fa;
    padding: 60px 0;
    text-align: center;
}

.blog-details-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.breadcrumb {
    font-size: 1rem;
    color: #666;
}

.breadcrumb a {
    color: #007bff;
    text-decoration: none;
}

.breadcrumb a:hover {
    text-decoration: underline;
}

/* Blog Details Container */
.blog-details-container {
    padding: 40px 0;
}

.blog-details-content {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
}

.blog-details-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
}

.blog-details-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
}

.blog-details-description h2,
.blog-details-description h3,
.blog-details-description h4 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #333;
}

.blog-details-description p {
    margin-bottom: 15px;
}

.blog-details-description a {
    color: #007bff;
    text-decoration: none;
}

.blog-details-description a:hover {
    text-decoration: underline;
}