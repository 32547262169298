.footer_contact{
    color: #121212;
    font-family: Suez One;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 115.5%; /* 41.58px */
    text-transform: capitalize;
}
.footer_sub_text{
    color: rgba(14, 14, 14, 0.81) !important;

font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 36px;
}
.link_text{
    color: #121212 !important;
}
.btn-outline-primary{
    border-color: #F7631B !important
}
.btn-outline-primary:hover{
    background-color: #F7631B !important;
}
.link_text:hover{
    color: #000 !important;
}
.footer_contact{
    color: #121212;
    font-family: Suez One;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 115.5%; /* 41.58px */
    text-transform: capitalize;
}
.footer_sub_text{
    color: rgba(14, 14, 14, 0.81) !important;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}