::-moz-selection {
  background-color: #f7631b;
  color: #fff;
}
::selection {
  background-color: #f7631b;
  color: #fff;
}
::-webkit-input-placeholder {
  color: #777777;
  font-weight: 300;
}
:-moz-placeholder {
  color: #777777;
  opacity: 1;
  font-weight: 300;
}
::-moz-placeholder {
  color: #777777;
  opacity: 1;
  font-weight: 300;
}
:-ms-input-placeholder {
  color: #777777;
  font-weight: 300;
}
::-ms-input-placeholder {
  color: #777777;
  font-weight: 300;
}
body {
  color: #777;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.625em;
  position: relative;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
html{
  overflow-x: hidden;
  width: 100%;
}
select {
  display: block;
}
figure {
  margin: 0;
}
a {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
iframe {
  border: 0;
}
a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}
#root{
  overflow-x: hidden;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  text-decoration: none;
  outline: 0;
}
.card-panel {
  margin: 0;
  padding: 60px;
}
.btn i,
.btn-large i,
.btn-floating i,
.btn-large i,
.btn-flat i {
  font-size: 1em;
  line-height: inherit;
}
.gray-bg {
  background: #f9f9ff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  color: #222;
  line-height: 1.2em !important;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 600;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #222;
}
h1,
.h1 {
  font-size: 36px;
}
h2,
.h2 {
  font-size: 30px;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 16px;
}
h6,
.h6 {
  font-size: 14px;
  color: #222;
}
td,
th {
  border-radius: 0px;
}
.clear::before,
.clear::after {
  content: " ";
  display: table;
}
.clear::after {
  clear: both;
}
.fz-11 {
  font-size: 11px;
}
.fz-12 {
  font-size: 12px;
}
.fz-13 {
  font-size: 13px;
}
.fz-14 {
  font-size: 14px;
}
.fz-15 {
  font-size: 15px;
}
.fz-16 {
  font-size: 16px;
}
.fz-18 {
  font-size: 18px;
}
.fz-30 {
  font-size: 30px;
}
.fz-48 {
  font-size: 48px !important;
}
.fw100 {
  font-weight: 100;
}
.fw300 {
  font-weight: 300;
}
.fw400 {
  font-weight: 400 !important;
}
.fw500 {
  font-weight: 500;
}
.f700 {
  font-weight: 700;
}
.fsi {
  font-style: italic;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-120 {
  margin-top: 120px;
}
.mt-150 {
  margin-top: 150px;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-50 {
  margin-left: 50px;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-15 {
  margin-right: 15px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-50 {
  margin-right: 50px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-0-i {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-100 {
  margin-bottom: 100px;
}
.pt-0 {
  padding-top: 0px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-120 {
  padding-top: 120px;
}
.pt-150 {
  padding-top: 150px;
}
.pt-170 {
  padding-top: 170px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-120 {
  padding-bottom: 120px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pr-30 {
  padding-right: 30px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-90 {
  padding-left: 90px;
}
.p-40 {
  padding: 40px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.text-italic {
  font-style: italic;
}
.text-white {
  color: #fff;
}
.text-black {
  color: #000;
}
.transition {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.section-full {
  padding: 100px 0;
}
.section-half {
  padding: 75px 0;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-rigth {
  text-align: right;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.flex-grow {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
}
.flex-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.flex-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}
.flex-top {
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.flex-bottom {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
}
.flex-column {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.display-table {
  display: table;
}
.light {
  color: #fff;
}
.dark {
  color: #222;
}
.relative {
  position: relative;
}
.overflow-hidden {
  overflow: hidden;
}
.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.container.fullwidth {
  width: 100%;
}
.container.no-padding {
  padding-left: 0;
  padding-right: 0;
}
.no-padding {
  padding: 0;
}
.section-bg {
  background: #f9fafc;
}
@media (max-width: 767px) {
  .no-flex-xs {
    display: block !important;
  }
}
.row.no-margin {
  margin-left: 0;
  margin-right: 0;
}
.whole-wrap {
  background-color: #f9f9ff;
}
.generic-banner {
  margin-top: 60px;
  background-color: #f7631b;
  text-align: center;
}
.generic-banner .height {
  height: 600px;
}
@media (max-width: 767.98px) {
  .generic-banner .height {
    height: 400px;
  }
  .contact_img{
    display: none;
  }
  .bg_black{
    margin-left: 0px !important;
  }
  .home_button{
    width: 94%;
  }
  .attach_field{
    margin-left: 18% !important;
    margin-right: 15% !important;
  }
  .banner_img{
    width: 100%;
  }
  .testi_card{
    height: auto !important;
  }
  .banner_image{
    width: 100%;
}
}
.generic-banner .generic-banner-content h2 {
  line-height: 1.2em;
  margin-bottom: 20px;
}
@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content h2 br {
    display: none;
  }
  .contact_img{
    display: none;
  }
  .bg_black{
    margin-left: 0px !important;
  }
  .home_button{
    width: 94%;
  }
  .attach_field{
    margin-left: 18% !important;
    margin-right: 15% !important;
  }
  .banner_img{
    width: 100%;
  }
  .testi_card{
    height: auto !important;
  }
  .banner_image{
    width: 100%;
}
}
.generic-banner .generic-banner-content p {
  text-align: center;
  font-size: 16px;
}
@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content p br {
    display: none;
  }
  .contact_img{
    display: none;
  }
  .bg_black{
    margin-left: 0px !important;
  }
  .home_button{
    width: 94%;
  }
  .attach_field{
    margin-left: 18% !important;
    margin-right: 15% !important;
  }
  .banner_img{
    width: 100%;
  }
  .testi_card{
    height: auto !important;
  }
  .banner_image{
    width: 100%;
}
}
.generic-content h1 {
  font-weight: 600;
}
.about-generic-area {
  background: #fff;
}
.about-generic-area p {
  margin-bottom: 20px;
}
.white-bg {
  background: #fff;
}
.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee;
}
.switch-wrap {
  margin-bottom: 10px;
}
.switch-wrap p {
  margin: 0;
}
.sample-text-area {
  background: #f9f9ff;
  padding: 100px 0 70px 0;
}
.sample-text {
  margin-bottom: 0;
}
.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}
.typo-list {
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .typo-sec {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .element-wrap {
    margin-top: 30px;
  }
}
b,
sup,
sub,
u,
del {
  color: #f7631b;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5em;
}
.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  color: #777;
}
.button-area {
  background: #f9f9ff;
}
.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}
.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}
.button-group-area .genric-btn:last-child {
  margin-right: 0;
}
.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: 0.8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.genric-btn:focus {
  outline: none;
}
.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}
.genric-btn.large {
  line-height: 45px;
}
.genric-btn.medium {
  line-height: 30px;
}
.genric-btn.small {
  line-height: 25px;
}
.genric-btn.radius {
  border-radius: 3px;
}
.genric-btn.circle {
  border-radius: 20px;
}
.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.genric-btn.arrow span {
  margin-left: 10px;
}
.genric-btn.default {
  color: #222;
  background: #f9f9ff;
  border: 1px solid transparent;
}
.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}
.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}
.genric-btn.default-border:hover {
  color: #222;
  background: #f9f9ff;
  border: 1px solid transparent;
}
.genric-btn.primary {
  color: #fff;
  background: #f7631b;
  border: 1px solid transparent;
}
.genric-btn.primary:hover {
  color: #f7631b;
  border: 1px solid #f7631b;
  background: #fff;
}
.genric-btn.primary-border {
  color: #f7631b;
  border: 1px solid #f7631b;
  background: #fff;
}
.genric-btn.primary-border:hover {
  color: #fff;
  background: #f7631b;
  border: 1px solid transparent;
}
.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}
.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}
.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}
.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}
.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}
.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}
.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}
.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}
.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}
.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}
.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}
.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}
.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}
.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}
.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}
.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}
.genric-btn.link {
  color: #222;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}
.genric-btn.link:hover {
  color: #222;
  border: 1px solid #f9f9ff;
  background: #fff;
}
.genric-btn.link-border {
  color: #222;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}
.genric-btn.link-border:hover {
  color: #222;
  background: #f9f9ff;
  border: 1px solid transparent;
}
.genric-btn.disable {
  color: #222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}
.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #fff;
  border-left: 2px solid #f7631b;
}
@media (max-width: 991px) {
  .progress-table-wrap {
    overflow-x: scroll;
  }
}
.progress-table {
  background: #fff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}
.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}
.progress-table .country {
  width: 28.07%;
}
.progress-table .visit {
  width: 19.74%;
}
.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}
.progress-table .table-head {
  display: flex;
}
.progress-table .table-head .serial,
.progress-table .table-head .country,
.progress-table .table-head .visit,
.progress-table .table-head .percentage {
  color: #222;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}
.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex;
}
.progress-table .table-row .serial,
.progress-table .table-row .country,
.progress-table .table-row .visit,
.progress-table .table-row .percentage {
  display: flex;
  align-items: center;
}
.progress-table .table-row .country img {
  margin-right: 15px;
}
.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}
.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}
.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}
.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}
.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}
.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}
.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}
.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}
.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}
.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}
.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-gallery-image:hover {
  opacity: 0.8;
}
.list-style {
  width: 14px;
  height: 14px;
}
.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
}
.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #f7631b;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}
.ordered-list {
  margin-left: 30px;
}
.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #f7631b;
  font-weight: 500;
  line-height: 1.82em !important;
}
.ordered-list li span {
  font-weight: 300;
  color: #777;
}
.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #f7631b;
  font-weight: 500;
  line-height: 1.82em !important;
}
.ordered-list-alpha li span {
  font-weight: 300;
  color: #777;
}
.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #f7631b;
  font-weight: 500;
  line-height: 1.82em !important;
}
.ordered-list-roman li span {
  font-weight: 300;
  color: #777;
}
.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #fff;
  padding: 0 20px;
}
.single-input:focus {
  outline: none;
}
.input-group-icon {
  position: relative;
}
.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}
.input-group-icon .icon i {
  color: #797979;
}
.input-group-icon .single-input {
  padding-left: 45px;
}
.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #fff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}
.single-textarea:focus {
  outline: none;
}
.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #fff;
  padding: 0 20px;
}
.single-input-primary:focus {
  outline: none;
  border: 1px solid #f7631b;
}
.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #fff;
  padding: 0 20px;
}
.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}
.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #fff;
  padding: 0 20px;
}
.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}
.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.default-switch input + label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #f7631b;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.default-switch input:checked + label {
  left: 19px;
}
.single-element-widget {
  margin-bottom: 30px;
}
.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.primary-switch input:checked + label:after {
  left: 19px;
}
.primary-switch input:checked + label:before {
  background: #f7631b;
}
.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.confirm-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.confirm-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}
.confirm-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.confirm-switch input:checked + label:after {
  left: 19px;
}
.confirm-switch input:checked + label:before {
  background: #4cd3e3;
}
.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.primary-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.primary-checkbox input:checked + label {
  background: url(../img/elements/primary-check.png) no-repeat center
    center/cover;
  border: none;
}
.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.confirm-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.confirm-checkbox input:checked + label {
  background: url(../img/elements/success-check.png) no-repeat center
    center/cover;
  border: none;
}
.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.disabled-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}
.disabled-checkbox input:checked + label {
  background: url(../img/elements/disabled-check.png) no-repeat center
    center/cover;
  border: none;
}
.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.primary-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.primary-radio input:checked + label {
  background: url(../img/elements/primary-radio.png) no-repeat center
    center/cover;
  border: none;
}
.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.confirm-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.confirm-radio input:checked + label {
  background: url(../img/elements/success-radio.png) no-repeat center
    center/cover;
  border: none;
}
.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.disabled-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}
.disabled-radio input:checked + label {
  background: url(../img/elements/disabled-radio.png) no-repeat center
    center/cover;
  border: none;
}
.default-select {
  height: 40px;
}
.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #fff;
  padding-left: 20px;
  padding-right: 40px;
}
.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}
.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}
.default-select .nice-select .list .option.selected {
  color: #f7631b;
  background: transparent;
}
.default-select .nice-select .list .option:hover {
  color: #f7631b;
  background: transparent;
}
.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}
.default-select .nice-select::after {
  right: 20px;
}
@media (max-width: 991px) {
  .left-align-p p {
    margin-top: 20px;
  }
}
.form-select {
  height: 40px;
  width: 100%;
}
.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #fff !important;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}
.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}
.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}
.form-select .nice-select .list .option.selected {
  color: #f7631b;
  background: transparent;
}
.form-select .nice-select .list .option:hover {
  color: #f7631b;
  background: transparent;
}
.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}
.form-select .nice-select::after {
  right: 20px;
}
.header-top {
  background: #04091e;
  font-size: 12px;
  padding: 8px;
}
.header-top a {
  color: #fff;
}
.header-top ul li {
  display: inline-block;
  margin-left: 15px;
}
.header-top .header-top-left {
  text-align: left;
}
.header-top .header-top-left i {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.header-top .header-top-left a {
  margin-right: 10px;
}
.header-top .header-top-left a:hover {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.header-top .header-top-left a:hover i {
  color: #f7631b;
}
.header-top .header-top-right {
  text-align: right;
}
.header-top .header-top-right a {
  margin-right: 10px;
}
.header-top .header-top-right a .lnr {
  display: none;
  font-weight: 700;
}
@media (max-width: 480px) {
  .header-top .header-top-right a .lnr {
    display: inline-block;
  }
  .header-top .header-top-right a .text {
    display: none;
  }
}
.top-head-btn {
  background: #f7631b;
  padding: 9px 26px;
}
.main-menu {
  padding-bottom: 20px;
  padding-top: 20px;
}
#header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
}
#header.header-scrolled .main-menu {
  border-color: transparent;
}
@media (max-width: 673px) {
  #logo {
    margin-left: 20px;
  }
}
#header #logo h1 {
  font-size: 34px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 3px;
}
#header #logo h1 a,
#header #logo h1 a:hover {
  color: #000;
  padding-left: 10px;
  border-left: 4px solid #f7631b;
}
#header #logo img {
  padding: 0;
  margin: 0;
}
@media (max-width: 768px) {
  #header #logo h1 {
    font-size: 28px;
  }
  #header #logo img {
    max-height: 40px;
  }
}
.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  right: 0;
  z-index: 99;
}
.nav-menu li {
  position: relative;
  white-space: nowrap;
}
.nav-menu > li {
  float: left;
}
.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
  display: block;
}
.nav-menu ul ul {
  top: 0;
  right: 100%;
}
.nav-menu ul li {
  min-width: 180px;
}
.sf-arrows .sf-with-ul {
  padding-right: 30px;
}
.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}
.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}
@media (max-width: 768px) {
  #nav-menu-container {
    display: none;
  }
}
.nav-menu a {
  padding: 0 8px 0px 8px;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  outline: none;
}
.nav-menu li:hover > a {
  color: #f7631b;
}
.nav-menu > li {
  margin-left: 10px;
}
.nav-menu ul {
  margin: 24px 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  background: #fff;
}
.nav-menu ul li {
  transition: 0.3s;
}
.nav-menu ul li a {
  padding: 5px 10px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 12px;
  text-transform: none;
}
.nav-menu ul li:hover > a {
  color: #f7631b;
}
.nav-menu ul ul {
  margin-right: 10px;
  margin-top: 0;
}
#mobile-nav-toggle {
  position: fixed;
  right: 15px;
  z-index: 999;
  top: 62px;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
}
#mobile-nav-toggle i {
  color: #fff;
  font-weight: 900;
}
@media (max-width: 960px) {
  #mobile-nav-toggle {
    display: inline;
  }
  #nav-menu-container {
    display: none;
  }
}
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.8);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}
#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#mobile-nav ul li {
  position: relative;
}
#mobile-nav ul li a {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
  font-weight: 400;
}
#mobile-nav ul li a:hover {
  color: #fff;
}
#mobile-nav ul li li {
  padding-left: 30px;
}
#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}
#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #f7631b;
}
#mobile-nav ul .menu-has-children li a {
  text-transform: none;
}
#mobile-nav ul .menu-item-active {
  color: #f7631b;
}
#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}
body.mobile-nav-active {
  overflow: hidden;
}
body.mobile-nav-active #mobile-nav {
  left: 0;
}
body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}
.section-gap {
  padding: 120px 0;
}
.footer-gap {
  /* padding: 120px 120px 50px 120px; */
  padding-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .footer-gap {
    /* padding: 10px 50px 50px 70px; */
  }
}

.alert-msg {
  color: #008000;
}
.primary-btn {
  background: #f7631b;
  line-height: 42px;
  padding-left: 30px;
  padding-right: 30px;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  position: relative;
}
.primary-btn:focus {
  outline: none;
}
.primary-btn span {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  right: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.primary-btn:hover {
  color: #fff;
}
.primary-btn:hover span {
  color: #fff;
  right: 20px;
}
.primary-btn.white {
  border: 1px solid #fff;
  color: #fff;
}
.primary-btn.white span {
  color: #fff;
}
.primary-btn.white:hover {
  background: #fff;
  color: #f7631b;
}
.primary-btn.white:hover span {
  color: #f7631b;
}
.primary-btn.wh {
  background: #fff;
  color: #222;
  text-transform: uppercase;
}
.primary-btn.wh:hover {
  background: #222;
  color: #fff;
}
.primary-btn.squire {
  border-radius: 0px !important;
  border: 1px solid transparent;
}
.primary-btn.squire:hover {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.section-title {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}
.section-title h1 {
  margin-bottom: 10px;
}
.banner-area {
  background: url(../image/banner-bg.jpg) right;
  background-size: cover;
 
}
.homebanner{
  padding-top: 250px;
  padding-bottom: 150px;
}
@media only screen and (max-width: 600px) {
  .banner-area {
    background: url(../image/banner-bg.jpg) right;
    background-size: cover;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.banner-area .overlay-bg {
  background-color: rgba(6, 6, 6, 0.71);
}
@media (max-width: 767px) {
  .fullscreen {
    height: 700px !important;
  }
}
.banner-content {
  text-align: left;
}
.banner-content h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1em;
  margin-top: 10px;
  color: #fff;
}
@media (max-width: 991.98px) {
  .banner-content h1 {
    font-size: 36px;
  }
}
@media (max-width: 991.98px) {
  .banner-content h1 br {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .banner-content h1 {
    font-size: 45px;
  }
}
@media (max-width: 480px) {
  .banner-content h1 {
    font-size: 32px;
  }
}
.banner-content .primary-btn {
  background-color: #f7631b;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.banner-content .primary-btn:hover {
  background-color: #fff;
  color: #f7631b;
}
.feature-area {
  margin-top: -62px;
}
.single-feature {
  text-align: center;
}
.single-feature .title {
  background: rgba(255, 255, 255, 0.15);
  padding: 20px 0px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-feature .title h4 {
  color: #fff;
}
.single-feature .desc-wrap {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 10px 30px 0px rgba(153, 153, 153, 0.1);
}
.single-feature .desc-wrap a {
  font-size: 14px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: #222;
}
.single-feature:hover {
  cursor: pointer;
}
.single-feature:hover .title {
  background: #f7631b;
}
.single-feature:hover .desc-wrap a {
  color: #f7631b;
}
@media (max-width: 991px) {
  .single-feature {
    margin-bottom: 30px;
  }
  .single-feature .title {
    background: #f7631b;
  }
}
.popular-course-area .owl-dots {
  text-align: center;
  bottom: 5px;
  margin-top: 40px;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
.popular-course-area .owl-dot {
  height: 10px;
  width: 10px;
  display: inline-block;
  background: rgba(127, 127, 127, 0.5);
  margin-left: 5px;
  margin-right: 5px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.popular-course-area .owl-dot.active {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: #f7631b;
}
.single-popular-carusel .thumb {
  overflow: hidden;
}
.single-popular-carusel .thumb img {
  /* width: 100%; */
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-popular-carusel .meta {
  margin-top: -26px;
  z-index: 2;
  position: inherit;
  padding: 0px 10px;
}
.single-popular-carusel .meta p {
  font-size: 12px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 0px;
}
.single-popular-carusel .meta p .lnr {
  margin: 0px 5px;
}
.single-popular-carusel .meta h4 {
  color: #fff;
}
.single-popular-carusel .details h4 {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin: 20px 0px;
}
.single-popular-carusel .details h4:hover {
  color: #f7631b !important;
}
@media (max-width: 576px) {
  .single-popular-carusel {
    margin: 15px;
  }
}
.single-popular-carusel:hover .thumb img {
  transform: scale(1.1);
}
.search-course-area {
  background: url(../image/s-bg.jpg) center;
  background-size: cover;
}
.search-course-area .overlay-bg {
  background: rgba(6, 6, 6, 0.71);
}
.search-course-area .search-course-left h1 {
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .search-course-area .search-course-left h1 {
    font-size: 30px;
  }
}
.search-course-area .search-course-left p {
  font-size: 15px;
  color: #fff;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
}
.justyfytext{
  text-align: justify !important;
}
@media (max-width: 991px) {
  .search-course-area .search-course-left {
    padding-top: 120px;
    margin-bottom: 40px;
  }
}
.search-course-area .search-course-right {
  background: rgba(255, 255, 255, 0.1);
}
.search-course-area .search-course-right .form-wrap {
  padding: 0px 40px;
}
.search-course-area .search-course-right .form-wrap .form-control {
  margin-bottom: 5px;
  border-radius: 0px;
  padding: 0.675rem 0.75rem;
  font-size: 13px;
  font-weight: 300;
}
.search-course-area .search-course-right .form-wrap .current {
  margin-left: -32px;
  font-size: 13px;
}
.search-course-area
  .search-course-right
  .form-wrap
  .form-select
  .nice-select
  .list
  .option {
  padding-left: 15px;
}
.search-course-area .search-course-right .form-wrap .primary-btn {
  width: 100%;
  margin-top: 5px;
}
.search-course-area .details-content {
  margin-top: 50px;
}
.search-course-area .details-content .single-detials span {
  color: #f7631b;
  font-size: 30px;
}
.search-course-area .details-content .single-detials h4 {
  margin: 20px 0px;
  color: #fff;
}
.search-course-area .details-content .single-detials p {
  margin-bottom: 0px;
}
@media (max-width: 991px) and (min-width: 768px) {
  .search-course-area .details-content .single-detials h4 {
    font-size: 12px;
  }
}
.active-upcoming-event-carusel .owl-dots {
  display: inline-grid;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  left: 101%;
  top: 40%;
}
.active-upcoming-event-carusel .owl-dot {
  height: 10px;
  width: 10px;
  display: inline-block;
  background: rgba(127, 127, 127, 0.5);
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.active-upcoming-event-carusel .owl-dot.active {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: #f7631b;
}
@media (max-width: 1024px) {
  .active-upcoming-event-carusel .owl-controls {
    display: none;
  }
}
@media (max-width: 576px) {
  .single-carusel {
    margin: 15px;
  }
}
.single-carusel h4 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.single-carusel p {
  margin-top: 0px !important;
  margin-bottom: 0px;
}
.single-carusel .thumb {
  overflow: hidden;
}
.single-carusel .thumb img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 100%;
}
.single-carusel .detials h4 {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-carusel .detials h4:hover {
  color: #f7631b;
}
.single-carusel .detials p {
  margin-top: 20px;
}
.single-carusel:hover .thumb img {
  transform: scale(1.05) rotate(-1.05deg);
}
.review-area {
  background: #f9f9ff;
}
.review-area h4 {
  color: #222;
}
.review-area .owl-carousel .owl-item img {
  width: auto !important;
}
/* .review-area .owl-dots {
  text-align: center;
  bottom: 5px;
  margin-top: 20px;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
} */

.single-review .title {
  margin: 20px 0;
}
.single-review .star {
  margin-left: 30px;
}
.single-review .star .checked {
  color: orange;
}
@media (max-width: 414px) {
  .single-review {
    padding: 15px;
  }
}
.single-blog .thumb {
  overflow: hidden;
}
.single-blog .thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-blog:hover img {
  transform: scale(1.1);
}
.single-blog:hover h5 {
  color: #f7631b;
}
.single-blog h5 {
  margin-bottom: 12px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
.single-blog a {
  color: #777;
}
.single-blog .meta {
  font-weight: 400;
  text-align: left;
  margin-top: 40px;
}
@media (max-width: 991px) {
  .single-blog {
    margin-bottom: 30px;
  }
}
.single-blog .details-btn {
  border: 1px solid #eee;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}
.single-blog .details-btn .details {
  visibility: hidden;
  opacity: 0;
  margin-left: -53px;
  font-weight: 600;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.single-blog .details-btn .lnr {
  color: #222;
  line-height: inherit;
}
.single-blog .details-btn:hover {
  width: 140px;
  background: #f7631b;
  box-shadow: 0px 10px 20px 0px rgba(60, 64, 143, 0.2);
}
.single-blog .details-btn:hover .details {
  margin-left: 0;
  visibility: visible;
  opacity: 1;
  margin-right: 8px;
}
.single-blog .details-btn:hover span {
  color: #fff;
  font-weight: 600;
}
.cta-one-area {
  background: url(../img/cta-bg.jpg) center;
  background-size: cover;
  text-align: center;
  color: #fff;
}
.cta-one-area h1,
.cta-one-area p {
  position: relative;
}
.cta-one-area p {
  margin: 20px 0px;
}
@media (max-width: 576px) {
  .cta-one-area p {
    padding: 0% 5%;
  }
}
.cta-one-area .overlay-bg {
  background: rgba(4, 9, 30, 0.2);
}
.cta-two-area {
  background: #f7631b;
  padding: 90px 0px;
}
.cta-two-area .cta-left {
  text-align: left;
}
.cta-two-area .cta-left h1 {
  color: #fff;
  font-weight: 700;
}
.cta-two-area .cta-right {
  text-align: right;
}
@media (max-width: 991px) {
  .cta-two-area .cta-right {
    text-align: left;
    margin-top: 20px;
  }
}
.link-nav {
  margin-top: 10px;
  margin-bottom: 0px;
}
.about-banner {
  padding-top: 30px;
  padding-bottom: 30px;
  background: url(../image/s-bg.jpg) right no-repeat;
  background-size: cover;
}
.about-content {
  margin-top: 110px;
  padding: 90px 0px;
  text-align: center;
}
@media (max-width: 767px) {
  .about-content {
    margin-top: 70px;
  }
}
.about-content h1 {
  font-size: 48px;
  font-weight: 600;
}
.about-content a {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
}
.about-content .lnr {
  margin: 0px 10px;
  font-weight: 600;
}
.info-area .info-area-left img {
  width: 100%;
}
.info-area .info-area-right {
  padding-left: 80px;
}
.info-area .info-area-right h1 {
  max-width: 400px;
  margin-bottom: 20px;
}
.info-area .info-area-right p {
  max-width: 450px;
  margin-bottom: 0px;
}
@media (max-width: 1024px) and (min-width: 991px) {
  .info-area .info-area-right h1 {
    font-size: 23px;
  }
}
@media (max-width: 1024px) {
  .info-area .info-area-right {
    padding-left: 15px;
  }
}
@media (max-width: 991px) {
  .info-area .info-area-right h1,
  .info-area .info-area-right p {
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  .info-area .info-area-right {
    margin-top: 50px;
  }
}
.accordion > dt {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
}
.accordion > dt > a {
  display: block;
  position: relative;
  color: #222222;
  text-decoration: none;
  padding: 8px 20px;
  background: #f9f9ff;
}
.accordion > dt > a:hover {
  text-decoration: none;
}
.accordion > dt > a.active {
  color: #fff;
  border-color: #ddd;
  background: #f7631b;
  cursor: default;
}
.accordion > dt > a:after {
  content: "\f107";
  width: 15px;
  height: 15px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  right: 10px;
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #111;
  -webkit-font-smoothing: antialiased;
}
.accordion > dt > a.active:after,
.accordion > dt > a.active:hover:after {
  content: "\f106";
  color: #fff;
}
.accordion > dt > a:hover:after {
  color: #444;
}
.accordion > dd {
  margin-bottom: 10px;
  padding: 10px 20px 20px;
  font-size: 14px;
  line-height: 1.8;
  color: #777;
}
.accordion > dt > a,
.accordion > dt > a:after,
.toggle > dt > a,
.toggle > dt > a:after {
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -moz-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -ms-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
}
dl.accordion.time-line {
  position: relative;
}
dl.accordion.time-line:before {
  border-left: 1px dashed #cecece;
  bottom: 10px;
  content: "";
  left: 15px;
  position: absolute;
  top: 25px;
  z-index: 0;
}
.accordion.time-line > dd {
  margin-bottom: 10px;
  padding: 10px 20px 20px 40px;
  font-size: 14px;
  line-height: 1.8;
  color: #777;
}
.accordion.time-line > dt > a:after {
  content: "\e648";
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  left: 0;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  font-size: 20px;
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  background: #222;
  padding-left: 3px;
}
.accordion.time-line > dt > a.active:after,
.accordion.time-line > dt > a.active:hover:after {
  content: "\e641";
  color: #fff;
}
.accordion.time-line > dt > a {
  display: block;
  position: relative;
  color: #222222;
  text-decoration: none;
  padding: 14px 20px 14px 40px;
  border: none;
  letter-spacing: 3px;
}
.accordion.time-line > dt > a:hover,
.accordion.time-line > dt > a.active {
  background: none !important;
}
.toggle > dt {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 400;
}
.toggle > dt > a {
  display: block;
  position: relative;
  color: #222222;
  text-decoration: none;
  padding: 8px 20px;
  border-bottom: 1px solid #eee;
}
.toggle > dt > a:hover {
  text-decoration: none;
  border-color: #e8e8e8;
}
.toggle > dt > a.active {
  color: #f7631b;
  border-color: #f7631b;
}
.toggle > dt > a:after {
  content: "\f107";
  width: 15px;
  height: 15px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  right: 10px;
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #999;
  -webkit-font-smoothing: antialiased;
}
.toggle > dt > a.active:after,
.toggle > dt > a.active:hover:after {
  content: "\f106";
  color: #f7631b;
}
.toggle > dt > a:hover:after {
  color: #444;
}
.toggle > dd {
  margin-bottom: 10px;
  padding: 10px 20px 20px;
  font-size: 14px;
  line-height: 1.8;
  color: #777;
}
dl.toggle.time-line {
  position: relative;
}
dl.toggle.time-line:before {
  border-left: 1px dashed #cecece;
  bottom: 10px;
  content: "";
  left: 15px;
  position: absolute;
  top: 25px;
  z-index: 0;
}
.toggle.time-line > dd {
  margin-bottom: 10px;
  padding: 10px 20px 20px 40px;
  font-size: 14px;
  line-height: 1.8;
  color: #777;
}
.toggle.time-line > dt > a:after {
  content: "\e648";
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  left: 0;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  font-size: 20px;
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  background: #222;
  padding-left: 3px;
}
.toggle.time-line > dt > a.active:after,
.toggle.time-line > dt > a.active:hover:after {
  content: "\e641";
  color: #fff;
}
.toggle.time-line > dt > a {
  display: block;
  position: relative;
  color: #222222;
  text-decoration: none;
  padding: 14px 20px 14px 40px;
  border: none;
  letter-spacing: 3px;
}
.toggle.time-line > dt > a:hover,
.toggle.time-line > dt > a.active {
  background: none !important;
}
.jq-tab-menu .jq-tab-title:first-child {
  border-top: none;
}
.courses-page .single-popular-carusel {
  margin-bottom: 40px;
}
.courses-page .primary-btn {
  border: 1px solid transparent;
}
.courses-page .primary-btn:hover {
  background: transparent;
  border-color: #f7631b;
  color: #f7631b;
}
.left-contents .jq-tab-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 50px;
}
.left-contents .jq-tab-wrapper.horizontal-tab {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.left-contents .jq-tab-menu {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
@media (max-width: 413px) {
  .left-contents .jq-tab-menu {
    display: initial !important;
  }
}
.left-contents .jq-tab-menu .jq-tab-title {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  padding: 6px 25px;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media (max-width: 504px) {
  .left-contents .jq-tab-menu .jq-tab-title {
    padding: 0.9em 0.9em;
    font-size: 10px;
  }
}
.left-contents .jq-tab-menu .jq-tab-title:hover,
.left-contents .jq-tab-menu .jq-tab-title.active {
  color: #f7631b;
  background: transparent;
  border-bottom: 1px solid #f7631b;
}
.left-contents .horizontal-tab .jq-tab-menu {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.left-contents .jq-tab-content-wrapper {
  -webkit-box-flex: 4;
  -ms-flex: 4;
  flex: 4;
}
.left-contents .jq-tab-content {
  display: none;
  padding: 1em 1.8em;
  font-size: 14px;
  font-weight: 300;
}
.left-contents #verticalTab .jq-tab-content-wrapper {
  border: 1px solid #eee;
}
.left-contents #horizontalTab .jq-tab-content-wrapper {
  background: #f9f9ff;
}
.left-contents .jq-tab-content.active {
  display: block;
}
@media (max-width: 413px) {
  .left-contents .comment-wrap {
    padding: 0px !important;
  }
}
.left-contents .course-list li {
  margin-bottom: 20px;
}
.left-contents .course-list li .primary-btn {
  background: #fff;
  color: #222;
}
.left-contents .course-list li .primary-btn:hover {
  background: #f7631b;
  color: #fff;
}
@media (max-width: 414px) {
  .left-contents .course-list li {
    font-size: 8px;
  }
}
.left-contents .comments-area {
  padding: 0px 20px;
  margin-top: 50px;
  background: transparent !important;
  border: none !important;
}
@media (max-width: 414px) {
  .left-contents .comments-area {
    padding: 50px 8px;
  }
}
.left-contents .comments-area h4 {
  text-align: center;
  margin-bottom: 50px;
  font-weight: 600;
}
.left-contents .comments-area a {
  color: #222;
}
.left-contents .comments-area .comment-list {
  padding-bottom: 30px;
}
.left-contents .comments-area .comment-list:last-child {
  padding-bottom: 0px;
}
.left-contents .comments-area .comment-list.left-padding {
  padding-left: 25px;
}
@media (max-width: 414px) {
  .left-contents .comments-area .comment-list .single-comment h5 {
    font-size: 12px;
  }
  .left-contents .comments-area .comment-list .single-comment .date {
    font-size: 11px;
  }
  .left-contents .comments-area .comment-list .single-comment .comment {
    font-size: 10px;
  }
}
.left-contents .comments-area .thumb {
  margin-right: 20px;
}
.left-contents .comments-area .desc h5 {
  font-weight: 600;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.left-contents .comments-area .desc h5:hover a {
  color: #f7631b !important;
}
.left-contents .comments-area .date {
  font-size: 13px;
  color: #cccccc;
}
.left-contents .comments-area .comment {
  color: #777777;
  margin-bottom: 0px;
}
.left-contents .comments-area .btn-reply {
  background-color: #fcfaff;
  color: #222;
  font-weight: 400;
  padding: 8px 30px;
  display: block;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.left-contents .comments-area .btn-reply:hover {
  background: #f7631b;
  color: #fff;
}
.left-contents .comment-form {
  border: none;
}
.left-contents .star .checked {
  color: #f7631b;
}
.left-contents .reviews span,
.left-contents .reviews .star {
  width: 25%;
  margin-bottom: 10px;
}
.left-contents .avg-review {
  background: #04091e;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 0px;
}
.left-contents .avg-review span {
  font-size: 18px;
  color: #f7631b;
}
@media (max-width: 991px) {
  .left-contents .avg-review {
    margin-bottom: 20px;
  }
}
.left-contents .single-reviews h5 {
  display: inline-flex;
}
.left-contents .single-reviews h5 .star {
  margin-left: 10px;
}
.right-contents ul li {
  background: #f9f9ff;
  padding: 10px 22px;
  margin-bottom: 10px;
}
.right-contents ul li a {
  color: #222;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.right-contents ul li a p {
  margin-bottom: 0px;
}
.right-contents ul li .or {
  color: #f7631b !important;
}
.right-contents .primary-btn {
  margin-top: 10px;
  width: 100%;
  background: #222;
  text-align: center;
}
@media (max-width: 991px) {
  .right-contents {
    margin-top: 30px;
  }
}
.event-page-lists .single-carusel {
  margin-bottom: 50px;
}
.event-page-lists .primary-btn {
  border: 1px solid transparent;
}
.event-page-lists .primary-btn:hover {
  color: #f7631b;
  border-color: #f7631b;
  background: transparent;
}
.event-details-area .details-content h4 {
  margin: 20px 0px;
}
.event-details-area .social-nav {
  padding-top: 50px;
}
.event-details-area .social-nav .focials li {
  text-align: right;
  display: inline-block;
}
.event-details-area .social-nav .focials li a {
  color: #fff;
}
.event-details-area .social-nav .focials li a .fa-facebook {
  width: 30px;
  display: block;
  text-align: center;
  padding: 8px 0px;
  background: #4b6eaa;
}
.event-details-area .social-nav .focials li a .fa-twitter {
  width: 30px;
  display: block;
  text-align: center;
  padding: 8px 0px;
  background: #00c7f8;
}
.event-details-area .social-nav .focials li a .fa-dribbble {
  width: 30px;
  display: block;
  text-align: center;
  padding: 8px 0px;
  background: #f982aa;
}
.event-details-area .social-nav .focials li a .fa-behance {
  width: 30px;
  display: block;
  text-align: center;
  padding: 8px 0px;
  background: #0b8eff;
}
.event-details-area .social-nav .navs {
  text-align: right;
}
.event-details-area .social-nav .navs a {
  background: #f9f9ff;
  color: #222;
  padding: 10px 25px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
}
@media (max-width: 414px) {
  .event-details-area .social-nav .navs a {
    font-size: 12px;
  }
}
.event-details-area .social-nav .navs a:hover {
  background: #f7631b;
  color: #fff;
}
.event-details-area .social-nav .navs .nav-prev .lnr {
  margin-right: 5px;
}
.event-details-area .social-nav .navs .nav-next .lnr {
  margin-left: 5px;
}
@media (max-width: 767px) {
  .event-details-area .social-nav .navs {
    margin-top: 30px;
    text-align: left;
  }
}
@media (max-width: 991px) {
  .event-details-area .event-details-right {
    margin-top: 30px;
  }
}
.event-details-area .event-details-right .single-event-details {
  background: #f9f9ff;
  padding: 30px;
  margin-bottom: 30px;
}
.event-details-area .event-details-right .single-event-details ul li {
  font-size: 14px;
  border-bottom: 1px solid #eee;
  padding: 5px 0px;
}
.event-details-area
  .event-details-right
  .single-event-details
  ul
  li:last-child {
  border-bottom: none;
}
.gallery-area .single-imgs {
  margin-bottom: 30px;
}
.gallery-area .single-imgs img {
  width: 100%;
}
.gallery-area .single-imgs .overlay-bg {
  background: rgba(247, 99, 27, 0.85);
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 2;
}
.gallery-area .single-imgs:hover .overlay-bg {
  opacity: 1;
}
.blog-header-content h1 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 414px) {
  .blog-header-content h1 {
    font-size: 30px;
  }
}
.blog-header-content p {
  margin: 20px 0px;
}
.top-category-widget-area {
  background-color: #f9f9ff;
}
.top-category-widget-area .single-cat-widget {
  position: relative;
  text-align: center;
}
.top-category-widget-area .single-cat-widget .overlay-bg {
  background: rgba(0, 0, 0, 0.85);
  margin: 7%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.top-category-widget-area .single-cat-widget img {
  width: 100%;
}
.top-category-widget-area .single-cat-widget .content-details {
  top: 32%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  bottom: 0px;
  position: absolute;
}
.top-category-widget-area .single-cat-widget .content-details h4 {
  color: #fff;
}
.top-category-widget-area .single-cat-widget .content-details span {
  display: inline-block;
  background: #fff;
  width: 60%;
  height: 1%;
}
.top-category-widget-area .single-cat-widget:hover .overlay-bg {
  background: rgba(247, 99, 27, 0.85);
  margin: 7%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media (max-width: 991px) {
  .top-category-widget-area .single-cat-widget {
    margin-bottom: 50px;
  }
}
.post-content-area {
  background-color: #f9f9ff;
}
.post-content-area .single-post {
  margin-bottom: 50px;
}
.post-content-area .single-post .meta-details {
  text-align: right;
  margin-top: 35px;
}
@media (max-width: 767px) {
  .post-content-area .single-post .meta-details {
    text-align: left;
  }
}
.post-content-area .single-post .meta-details .tags {
  margin-bottom: 30px;
}
.post-content-area .single-post .meta-details .tags li {
  display: inline-block;
  font-size: 14px;
}
.post-content-area .single-post .meta-details .tags li a {
  color: #222;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.post-content-area .single-post .meta-details .tags li a:hover {
  color: #f7631b;
}
@media (max-width: 1024px) {
  .post-content-area .single-post .meta-details {
    margin-top: 0px;
  }
}
.post-content-area .single-post .user-name a,
.post-content-area .single-post .date a,
.post-content-area .single-post .view a,
.post-content-area .single-post .comments a {
  color: #777;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.post-content-area .single-post .user-name a:hover,
.post-content-area .single-post .date a:hover,
.post-content-area .single-post .view a:hover,
.post-content-area .single-post .comments a:hover {
  color: #f7631b;
}
.post-content-area .single-post .user-name .lnr,
.post-content-area .single-post .date .lnr,
.post-content-area .single-post .view .lnr,
.post-content-area .single-post .comments .lnr {
  font-weight: 900;
  color: #222;
}
.post-content-area .single-post .feature-img img {
  width: 100%;
}
.post-content-area .single-post .posts-title h3 {
  margin: 20px 0px;
}
.post-content-area .single-post .excert {
  margin-bottom: 20px;
}
.post-content-area .single-post .primary-btn {
  background: #fff !important;
  color: #222 !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 0px !important;
}
.post-content-area .single-post .primary-btn:hover {
  background: #f7631b !important;
  color: #fff !important;
}
.posts-list .posts-title h3 {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.posts-list .posts-title h3:hover {
  color: #f7631b;
}
.blog-pagination {
  padding-top: 60px;
  padding-bottom: 120px;
}
.blog-pagination .page-link {
  border-radius: 0;
}
.blog-pagination .page-item {
  border: none;
}
.page-link {
  background: transparent;
  font-weight: 400;
}
.blog-pagination .page-item.active .page-link {
  background-color: #f7631b;
  border-color: transparent;
  color: #fff;
}
.blog-pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #777;
  border: none;
}
.blog-pagination .page-link .lnr {
  font-weight: 600;
}
.blog-pagination .page-item:last-child .page-link,
.blog-pagination .page-item:first-child .page-link {
  border-radius: 0;
}
.blog-pagination .page-link:hover {
  color: #fff;
  text-decoration: none;
  background-color: #f7631b;
  border-color: #eee;
}
.sidebar-widgets {
  padding-bottom: 120px;
}
.widget-wrap {
  background: #fafaff;
  padding: 20px 0px;
  border: 1px solid #eee;
}
@media (max-width: 991px) {
  .widget-wrap {
    margin-top: 50px;
  }
}
.widget-wrap .single-sidebar-widget {
  margin: 30px 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}
.widget-wrap .single-sidebar-widget:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}
.widget-wrap .search-widget form.search-form input[type="text"] {
  color: #fff;
  padding: 10px 22px;
  font-size: 14px;
  border: none;
  float: left;
  width: 80%;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  background: #f7631b;
}
.widget-wrap .search-widget ::-webkit-input-placeholder {
  color: #fff;
}
.widget-wrap .search-widget ::-moz-placeholder {
  color: #fff;
}
.widget-wrap .search-widget :-ms-input-placeholder {
  color: #fff;
}
.widget-wrap .search-widget :-moz-placeholder {
  color: #fff;
}
.widget-wrap .search-widget form.search-form button {
  float: left;
  width: 20%;
  padding: 10px;
  background: #f7631b;
  color: #fff;
  font-size: 17px;
  border: none;
  cursor: pointer;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
.widget-wrap .search-widget form.search-form::after {
  content: "";
  clear: both;
  display: table;
}
.widget-wrap .user-info-widget {
  text-align: center;
}
.widget-wrap .user-info-widget a h4 {
  margin-top: 40px;
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.widget-wrap .user-info-widget .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}
.widget-wrap .user-info-widget .social-links li a {
  color: #222;
  padding: 10px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.widget-wrap .user-info-widget .social-links li a:hover {
  color: #f7631b;
}
.widget-wrap .popular-post-widget .popular-title {
  background: #f7631b;
  color: #fff;
  text-align: center;
  padding: 12px 0px;
}
.widget-wrap .popular-post-widget .popular-post-list {
  margin-top: 30px;
}
.widget-wrap .popular-post-widget .popular-post-list .thumb img {
  width: 100%;
}
.widget-wrap .popular-post-widget .single-post-list {
  margin-bottom: 20px;
}
.widget-wrap .popular-post-widget .single-post-list .details {
  margin-left: 12px;
}
.widget-wrap .popular-post-widget .single-post-list .details h6 {
  font-weight: 600;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.widget-wrap .popular-post-widget .single-post-list .details h6:hover {
  color: #f7631b;
}
.widget-wrap .popular-post-widget .single-post-list .details p {
  margin-bottom: 0px;
}
.widget-wrap .ads-widget img {
  width: 100%;
}
.widget-wrap .post-category-widget .category-title {
  background: #f7631b;
  color: #fff;
  text-align: center;
  padding: 12px 0px;
}
.widget-wrap .post-category-widget .cat-list li {
  padding-top: 15px;
  padding-bottom: 8px;
  border-bottom: 2px dotted #eee;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.widget-wrap .post-category-widget .cat-list li p {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 0px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.widget-wrap .post-category-widget .cat-list li a {
  color: #777;
}
.widget-wrap .post-category-widget .cat-list li:hover {
  border-color: #f7631b;
}
.widget-wrap .post-category-widget .cat-list li:hover p {
  color: #f7631b;
}
.widget-wrap .newsletter-widget .newsletter-title {
  background: #f7631b;
  color: #fff;
  text-align: center;
  padding: 12px 0px;
}
.widget-wrap .newsletter-widget .col-autos {
  width: 100%;
}
.widget-wrap .newsletter-widget p {
  text-align: center;
  margin: 20px 0px;
}
.widget-wrap .newsletter-widget .bbtns {
  background: #f7631b;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 0;
}
.widget-wrap .newsletter-widget .input-group-text {
  background: #fff;
  border-radius: 0px;
  vertical-align: top;
}
.widget-wrap .newsletter-widget .input-group-text i {
  color: #ccc;
  margin-top: -1px;
}
.widget-wrap .newsletter-widget .form-control {
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px;
  font-size: 13px;
  color: #ccc;
  padding: 9px;
  padding-left: 0px;
}
.widget-wrap .newsletter-widget .form-control:focus {
  box-shadow: none;
  border-color: #ccc;
}
.widget-wrap .newsletter-widget ::-webkit-input-placeholder {
  font-size: 13px;
  color: #ccc;
}
.widget-wrap .newsletter-widget ::-moz-placeholder {
  font-size: 13px;
  color: #ccc;
}
.widget-wrap .newsletter-widget :-ms-input-placeholder {
  font-size: 13px;
  color: #ccc;
}
.widget-wrap .newsletter-widget :-moz-placeholder {
  font-size: 13px;
  color: #ccc;
}
.widget-wrap .newsletter-widget .text-bottom {
  margin-bottom: 0px;
}
.widget-wrap .tag-cloud-widget .tagcloud-title {
  background: #f7631b;
  color: #fff;
  text-align: center;
  padding: 12px 0px;
  margin-bottom: 30px;
}
.widget-wrap .tag-cloud-widget ul li {
  display: inline-block;
  border: 1px solid #eee;
  background: #fff;
  padding: 4px 14px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.widget-wrap .tag-cloud-widget ul li:hover {
  background: #f7631b;
}
.widget-wrap .tag-cloud-widget ul li:hover a {
  color: #fff;
}
.widget-wrap .tag-cloud-widget ul li a {
  font-size: 12px;
  color: #222;
}
.single-post-area {
  padding-top: 80px;
  padding-bottom: 80px;
}
.single-post-area .meta-details {
  margin-top: 20px !important;
}
.single-post-area .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}
.single-post-area .social-links li a {
  color: #222;
  padding: 7px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .social-links li a:hover {
  color: #f7631b;
}
.single-post-area .quotes {
  margin-top: 20px;
  padding: 30px;
  background-color: #fff;
  box-shadow: -20.84px 21.58px 30px 0px rgba(176, 176, 176, 0.1);
}
.single-post-area .arrow {
  position: absolute;
}
.single-post-area .arrow .lnr {
  font-size: 20px;
  font-weight: 600;
}
.single-post-area .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8);
}
.single-post-area .navigation-area {
  border-top: 1px solid #eee;
  padding-top: 30px;
}
.single-post-area .navigation-area .nav-left {
  text-align: left;
}
.single-post-area .navigation-area .nav-left .thumb {
  margin-right: 20px;
  background: #000;
}
.single-post-area .navigation-area .nav-left .thumb img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-left .lnr {
  margin-left: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-left:hover .lnr {
  opacity: 1;
}
.single-post-area .navigation-area .nav-left:hover .thumb img {
  opacity: 0.5;
}
@media (max-width: 767px) {
  .single-post-area .navigation-area .nav-left {
    margin-bottom: 30px;
  }
}
.single-post-area .navigation-area .nav-right {
  text-align: right;
}
.single-post-area .navigation-area .nav-right .thumb {
  margin-left: 20px;
  background: #000;
}
.single-post-area .navigation-area .nav-right .thumb img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-right .lnr {
  margin-right: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-right:hover .lnr {
  opacity: 1;
}
.single-post-area .navigation-area .nav-right:hover .thumb img {
  opacity: 0.5;
}
@media (max-width: 991px) {
  .single-post-area .sidebar-widgets {
    padding-bottom: 0px;
  }
}
.comments-area {
  background: #fafaff;
  border: 1px solid #eee;
  padding: 50px 20px;
  margin-top: 50px;
}
@media (max-width: 414px) {
  .comments-area {
    padding: 50px 8px;
  }
}
.comments-area h4 {
  text-align: center;
  margin-bottom: 50px;
}
.comments-area a {
  color: #222;
}
.comments-area .comment-list {
  padding-bottom: 30px;
}
.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}
.comments-area .comment-list.left-padding {
  padding-left: 25px;
}
@media (max-width: 413px) {
  .comments-area .comment-list .single-comment h5 {
    font-size: 12px;
  }
  .comments-area .comment-list .single-comment .date {
    font-size: 11px;
  }
  .comments-area .comment-list .single-comment .comment {
    font-size: 10px;
  }
}
.comments-area .thumb {
  margin-right: 20px;
}
.comments-area .date {
  font-size: 13px;
  color: #cccccc;
}
.comments-area .comment {
  color: #777777;
}
.comments-area .btn-reply {
  background-color: #fff;
  color: #222;
  border: 1px solid #eee;
  padding: 8px 30px;
  display: block;
  font-weight: 600;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.comments-area .btn-reply:hover {
  background-color: #f7631b;
  color: #fff;
}
.comment-form {
  background: #fafaff;
  text-align: center;
  border: 1px solid #eee;
  padding: 50px 20px;
  margin-top: 50px;
}
.comment-form h4 {
  text-align: center;
  margin-bottom: 50px;
}
.comment-form .name {
  padding-left: 0px;
}
@media (max-width: 991px) {
  .comment-form .name {
    padding-right: 0px;
  }
}
.comment-form .email {
  padding-right: 0px;
}
@media (max-width: 991px) {
  .comment-form .email {
    padding-left: 0px;
  }
}
.comment-form .form-control {
  padding: 12px;
  background: #fff;
  border: none;
  border-radius: 0px;
  width: 100%;
  font-size: 13px;
  color: #777777;
}
.comment-form .form-control:focus {
  box-shadow: none;
  border: 1px solid #eee;
}
.comment-form ::-webkit-input-placeholder {
  font-size: 13px;
  color: #777;
}
.comment-form ::-moz-placeholder {
  font-size: 13px;
  color: #777;
}
.comment-form :-ms-input-placeholder {
  font-size: 13px;
  color: #777;
}
.comment-form :-moz-placeholder {
  font-size: 13px;
  color: #777;
}
.comment-form .primary-btn {
  border-radius: 0px !important;
  border: 1px solid transparent;
  color: #fff;
  font-weight: 600;
}
.comment-form .primary-btn:hover {
  background: transparent;
  border-color: #f7631b !important;
  color: #f7631b !important;
}
.contact-page-area {
  background: #f9f9ff;
}
.contact-page-area .map-wrap {
  margin-bottom: 120px;
}
@media (max-width: 991px) {
  .contact-page-area .map-wrap {
    margin-bottom: 50px;
  }
}
.contact-page-area .contact-btns {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 40px;
  color: #fff;
  margin-bottom: 30px;
}
.contact-page-area .contact-btns:hover {
  color: #fff;
}
.contact-page-area .form-area .form-control:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}
.contact-page-area .form-area input {
  padding: 15px;
}
.contact-page-area .form-area input,
.contact-page-area .form-area textarea {
  border-radius: 0;
  font-size: 13px !important;
}
.contact-page-area .form-area textarea {
  height: 190px;
  margin-top: 0px;
  padding: 15px;
}
.contact-page-area .form-area .genric-btn {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 0 !important;
  font-size: 14px;
  text-transform: uppercase !important;
}
.contact-page-area .form-area .genric-btn:hover {
  border: 1px solid #f7631b;
  color: #f7631b !important;
}
@media (max-width: 991px) {
  .contact-page-area .address-wrap {
    margin-bottom: 30px;
  }
}
.contact-page-area .address-wrap .single-contact-address {
  margin-bottom: 8px;
}
.contact-page-area .address-wrap .single-contact-address h5 {
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 5px;
}
.contact-page-area .address-wrap .single-contact-address .lnr {
  font-size: 30px;
  font-weight: 500;
  color: #f7631b;
  margin-right: 30px;
}
.footer-area {
  bottom: 0;
  background-color: black;
}
.single-footer-widget h4 {
  color: #fff;
  margin-bottom: 25px;
  font-weight: 500;
}
.single-footer-widget .form-control {
  border: none;
  width: 80% !important;
  font-weight: 300;
  background: #f9f9ff;
  padding-left: 20px;
  border-radius: 0;
  font-size: 14px;
  line-height: normal !important;
  color: #222;
}
.single-footer-widget .form-control:focus {
  box-shadow: none;
  color: #222;
}
.single-footer-widget ul li {
  margin-bottom: 10px;
}
.single-footer-widget ul li a {
  color: #777;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-footer-widget ul li a:hover {
  color: #f7631b;
}
.single-footer-widget .bb-btn {
  color: #f7631b;
  color: #fff;
  font-weight: 300;
  border-radius: 0;
  z-index: 9999;
  cursor: pointer;
}
.single-footer-widget .info {
  margin-top: 25px;
  color: #fff;
  font-size: 12px;
}
.single-footer-widget .info.valid {
  color: green;
}
.single-footer-widget .info.error {
  color: red;
}
.single-footer-widget .btn {
  font-size: 1.2rem;
  border-radius: 0;
  background: #f7631b;
  color: #fff;
}
.single-footer-widget .btn .lnr {
  line-height: inherit;
  font-size: 13px;
  padding: 0px 4px;
}
.single-footer-widget ::-moz-selection {
  background-color: #191919 !important;
  color: #777;
}
.single-footer-widget ::selection {
  background-color: #191919 !important;
  color: #777;
}
.single-footer-widget ::-webkit-input-placeholder {
  color: #777;
  font-weight: 300;
}
.single-footer-widget :-moz-placeholder {
  color: #777;
  opacity: 1;
  font-weight: 300;
}
.single-footer-widget ::-moz-placeholder {
  color: #777;
  opacity: 1;
  font-weight: 300;
}
.single-footer-widget :-ms-input-placeholder {
  color: #777;
  font-weight: 300;
}
.single-footer-widget ::-ms-input-placeholder {
  color: #777;
  font-weight: 300;
}
@media (max-width: 991.98px) {
  .single-footer-widget {
    margin-bottom: 30px;
  }
}
.footer-bottom {
  padding-top: 20px;
}
.footer-bottom .footer-social {
  text-align: right;
}
@media (max-width: 991px) {
  .footer-bottom .footer-social {
    text-align: left;
    margin-top: 30px;
  }
}
.footer-bottom .lnr,
.footer-bottom a {
  color: #f7631b;
}
.footer-bottom .footer-social a {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.footer-bottom .footer-social a i {
  vertical-align: bottom;
}
.footer-bottom .footer-social a:hover {
  background-color: #f7631b;
}
.form-control:focus{
  box-shadow: none !important;
  border-color:#CED4DA !important
}