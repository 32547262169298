.faq_head{
    color: #000;
    font-size: 22px;
    font-weight: 500;
}
.faq_sub_text{
    color: #394450;
    font-size: 17px;
    font-weight: 400;
    text-align: justify;
}
.faq_title{
    font-size: 60px;
    color: #F7631B;
    margin-bottom: 45px;
    text-align: justify;
}